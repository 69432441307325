import { render, staticRenderFns } from "./subPage.vue?vue&type=template&id=509e6c57&scoped=true&"
import script from "./subPage.vue?vue&type=script&lang=js&"
export * from "./subPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "509e6c57",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VProgressCircular,VSheet})
