<template>
  <div :class="fillHeight ? 'fill-height' : null">

    <portal to="dash-page-header-action">
      <slot name="header.action"></slot>
    </portal>
    <portal to="dash-page-header-action.desktop">
      <slot name="header.action.desktop"></slot>
    </portal>
    <portal to="dash-page-header-action.mobile">
      <slot name="header.action.mobile"></slot>
    </portal>

    <v-sheet v-if="loading" class="fill-height d-flex align-center justify-center">
      <v-progress-circular indeterminate :color="wsACCENT" />
    </v-sheet>

    <template v-else-if="!noContentCondition">
      <slot name="default"></slot>
      <slot v-if="!SM" name="default.desktop"></slot>
      <slot v-if="SM" name="default.mobile"></slot>
    </template>
    <slot v-else name="no_content">
      <wsBlankPage
          :text="noContentText"
          :icon="noContentIcon"
          :button-text="noContentButtonText"
          :button-action="noContentButtonAction"
          :route="noContentButtonRoute"
          :loading="noContentLoading"
      />
    </slot>
    <slot name="dialog"></slot>
  </div>
</template>

<script>
import wsBlankPage from "@/components/UI/wsBlankPage";
export default {
  name: "subPage",
  components : {
    wsBlankPage
  },
  props : {
    title : {
      type : String
    },
    noContentCondition : {
      type : Boolean,
      default : false
    },
    noContentText : {
      type : String
    },
    noContentIcon : {
      type : String
    },
    noContentButtonText : {
      type : String
    },
    noContentButtonRoute : {
      type : String
    },
    noContentButtonAction : {
      type : Function
    },
    noContentLoading : {
      type : Boolean,
      default : false
    },
    loading : {
      type : Boolean,
      default : false
    },
    fillHeight : {
      type : Boolean,
      default : false
    },
    value : {
      type : Object ,
      default : () => {}
    },
    headerAction : {
      type : Function
    },
    headerActionCondition : {
      default : null
    },
    headerActionText : {
      type : String
    },
    headerDropdownActions : {
      type : Array
    },
    root : {
      type : String
    },
    rootLocation : {
      type : String
    },
    filters : {
      type : Array,
      default : () => []
    },
    filtersData : {
      type : Object,
      default : () => {}
    },
    filtersSearch : {
      type : Boolean,
      default : false
    },
    filtersSearchAjax : {
      type : Function,
    },
    sideNavigation : {
      type : Array,
      default : () => []
    },
    navigationRoute : {
      type : Array,
    },
    icon : {
      type : String
    },
    headerRoute : {
      type : String
    },
    navigationReturnRoute : {
      type : String
    },
    subtitle : {
      type : String
    },
    preheaderTitle : {
      type : String
    },
    width : {
      type : [String,Number]
    },
    toggle : {
      type : Boolean,
      default : false
    }

  },
  data() {
    return {
      storeData : {}
    }
  },
  computed : {
    serverSearchTrigger() {
      return this.$store.state.dashboard.serverSearchTrigger
    }
  },
  watch : {
    serverSearchTrigger() {
      this.$emit('filters-search')
    },
    title(value) {
      if (this.storeData.title !== value ) {
        this.storeData.title = value
        this.storeData = Object.assign({} , this.storeData )
        this.$emit('input',this.storeData)
      }
    },
    sideNavigation : {
      handler() {
        this.storeData.sideNavigation = this.sideNavigation
        this.$emit('input',Object.assign({} , this.storeData) )
      },
      deep : true
    },
    headerActionCondition() {
      this.storeData.headerActionCondition = this.headerActionCondition
      this.$emit('input',Object.assign({} , this.storeData ))
    },
    filters : {
      handler() {
        this.storeData.filters = this.filters ?  JSON.parse(JSON.stringify(this.filters)) : []
        this.$emit('input' , Object.assign({} , this.storeData))
      },
      deep : true
    },
    headerAction() {
      this.storeData.headerAction = this.headerAction || null
    },
    filtersSearch() {
      this.storeData.filtersSearch = this.filtersSearch || false
      this.$emit('input',this.storeData)
    }

  },
  methods : {
    initPage() {

      this.storeData.title = this.title || ''
      this.storeData.filters = JSON.parse(JSON.stringify(this.filters)) || []
      this.storeData.icon = this.icon || null
      this.storeData.headerAction = this.headerAction || null
      this.storeData.headerActionCondition = this.headerActionCondition !== null ? this.headerActionCondition : null
      this.storeData.headerRoute = this.headerRoute || null
      this.storeData.root = this.root || null
      this.storeData.rootLocation = this.rootLocation || null
      this.storeData.navigationRoute = this.navigationRoute || null
      this.storeData.navigationReturnRoute = this.navigationReturnRoute || null
      this.storeData.subtitle = this.subtitle || null
      this.storeData.headerActionText = this.headerActionText || null
      this.storeData.sideNavigation = this.sideNavigation || []
      this.storeData.filtersData = this.filtersData ? JSON.parse(JSON.stringify(this.filtersData)) : {}
      this.storeData.width = this.width || '100%'
      this.storeData.toggle = this.toggle || false
      this.storeData.preheaderTitle = this.preheaderTitle || null

      this.storeData.headerDropdownActions = this.headerDropdownActions || null

      this.storeData.filtersSearch = this.filtersSearch || false
      this.storeData.filtersSearchAjax = this.filtersSearchAjax || null


      if ( !this.filtersData ) {
        this.$emit('resetFilter')
      }

      this.$emit('input',this.storeData)

    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>